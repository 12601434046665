import React, { useEffect, useState, useMemo } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LostInSpace from "../images/lostInSpace"
import Styles from "../styles/notFound.module.scss"
import CanvasBackgroundV2 from "../components/canvasBackgroundV2"

const NotFoundPage = () => {
  const [remainingSeconds, setRemainingSeconds] = useState(15)

  useEffect(() => {
    setTimeout(() => {
      setRemainingSeconds(remainingSeconds - 1)
    }, 1000)
  })

  useEffect(() => {
    if (remainingSeconds === 0) {
      setTimeout(() => {
        window.location.href = "/"
      }, 500)
    }
  }, [remainingSeconds])

  return (
    <Layout>
      <SEO title="404: Not found" />
      {useMemo(
        () => (
          <CanvasBackgroundV2 pageNotFound />
        ),
        []
      )}
      <div className={Styles.text}>
        <h1>Got lost?</h1>
        <p>
          Don't panic, we will put you on course in{" "}
          <span className={Styles.seconds}>{remainingSeconds}</span> seconds
        </p>
      </div>
      <div className={Styles.lostInSpaceContainer}>
        <LostInSpace className={Styles.lostInSpace} />
      </div>
    </Layout>
  )
}

export default NotFoundPage
