import React from "react"

import Logo from "../images/logo"
import SubHeader from "./subHeader"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Styles from "../styles/aboutSection.module.scss"
import Button from "./button"
import { Link } from "gatsby"

const AboutSection = ({ profileImage, aboutMe }) => {
  return (
    <div className={Styles.backdropContainer}>
      <div className={Styles.backdrop}>
        <div className={Styles.logoContainer}>
          <Logo className={Styles.logo} />
          {/* <h1 className={Styles.heading}>I build apps for the web </h1> */}
        </div>
        <div className={Styles.aboutContainer}>
          <div className={Styles.about}>
            <span className={Styles.aboutText}>
              <SubHeader emoji={"👦🏻"} className={Styles.aboutSubHeader}>
                About me
              </SubHeader>
              {documentToReactComponents(aboutMe.json)}
              <Link to="/contact">
                <Button text={"Get in touch"} />
              </Link>
            </span>
            <div className={Styles.profileImage}>
              <img
                src={profileImage.file.url}
                alt={profileImage.file.fileName}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutSection
