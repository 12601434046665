import React, { useRef, useEffect, useState, useCallback } from "react"

import AboutSection from "./aboutSection"

import Styles from "../styles/canvasBackground.module.scss"
import { skillsColors } from "../constants"

// The Dot object
function Dot(context, x, y, radius, distance, color) {
  this.x = x
  this.y = y
  this.color = color
  this.radius = radius
  this.dx = Math.random() - 0.5
  this.dy = Math.random() - 0.5

  //  generate angle between -45 and 45 degrees
  this.gradientAngleStart =
    Math.floor(Math.random() * 45) * (Math.round(Math.random()) ? 1 : -1)
  this.gradientAngleEnd = this.gradientAngleStart + 180

  // generate gradient points on the circle edge.
  this.gradientStart = {
    x: this.radius * Math.cos(this.gradientAngleStart),
    y: this.radius * Math.sin(this.gradientAngleStart),
  }

  this.gradientEnd = {
    x: this.radius * Math.cos(this.gradientAngleEnd),
    y: this.radius * Math.sin(this.gradientAngleEnd),
  }

  this.draw = () => {
    context.beginPath()

    // draw circle
    context.arc(this.x, this.y, this.radius, 0, 2 * Math.PI)

    // move gradient points to the circle's position
    this.gradientStart = {
      x: this.radius * Math.cos(this.gradientAngleStart) + this.x,
      y: this.radius * Math.sin(this.gradientAngleStart) + this.y,
    }

    this.gradientEnd = {
      x: this.radius * Math.cos(this.gradientAngleEnd) + this.x,
      y: this.radius * Math.sin(this.gradientAngleEnd) + this.y,
    }

    var gradient = context.createLinearGradient(
      this.gradientStart.x,
      this.gradientStart.y,
      this.gradientEnd.x,
      this.gradientEnd.y
    )

    gradient.addColorStop(0, this.color)
    gradient.addColorStop(1, "rgba(255, 255, 255, 0)")
    context.fillStyle = gradient

    context.fill()
  }

  this.move = () => {
    this.x += (this.dx * distance) / 3
    this.y += (this.dy * distance) / 3

    this.draw()
  }
}

const CanvasBackgroundV2 = ({ pageNotFound, profileImage, aboutMe }) => {
  const canvasRef = useRef()
  const [context, setContext] = useState()
  const [dotsArray, setDotsArray] = useState([])

  let innerHeight
  let innerWidth
  let devicePixelRatio

  if (typeof window !== `undefined`) {
    innerHeight = window.innerHeight
    innerWidth = window.innerWidth
    devicePixelRatio = window.devicePixelRatio
  }

  // makes the canvas sharp on any screen
  const makeCanvasGreat = useCallback(
    canvasCtx => {
      let canvas = document.getElementById("canvas")

      canvas.style.width = "100%"
      canvas.style.height = innerHeight + "px"

      canvas.width = Math.floor(innerWidth * devicePixelRatio)
      canvas.height = Math.floor(innerHeight * devicePixelRatio)
      // Normalize coordinate system to use css pixels.
      canvasCtx.scale(devicePixelRatio, devicePixelRatio)
    },
    [devicePixelRatio, innerWidth, innerHeight]
  )

  const initialPainting = useCallback(() => {
    if (context) {
      const colorsArray = Object.values(skillsColors)

      // numberOfDots is proportional to the screen size
      let numberOfDots = Math.floor(innerWidth / 50)

      // here is where we store the beautiful dots
      let dotsArray = []

      while (numberOfDots > 0) {
        // draw dots randomly on the screen
        const x = Math.floor(Math.random() * innerWidth)
        const y = Math.floor(Math.random() * innerHeight)
        const radius = 70 * (1 + Math.random())
        const distance = Math.random()

        const color = pageNotFound
          ? "#CFCFCF"
          : colorsArray[Math.floor(Math.random() * colorsArray.length)]

        let dot = new Dot(context, x, y, radius, distance, color)

        if (dotsArray.length) {
          let isNewDotIntersectingWithOtherDot = false
          for (const existingDot of dotsArray) {
            const distanceBetweenExistingDotAndNewDot = Math.sqrt(
              Math.pow(existingDot.x - dot.x, 2) +
                Math.pow(existingDot.y - dot.y, 2)
            )

            if (
              distanceBetweenExistingDotAndNewDot <=
              existingDot.radius + dot.radius
            ) {
              isNewDotIntersectingWithOtherDot = true
            }
          }

          if (!isNewDotIntersectingWithOtherDot) {
            dotsArray.push(dot)
            dot.draw()
            numberOfDots--
          }
        } else {
          dotsArray.push(dot)
          dot.draw()
          numberOfDots--
        }
      }

      return dotsArray
    }
  }, [context, innerWidth, innerHeight, pageNotFound])

  // here we add animation to the canvas dots
  useEffect(() => {
    let animationId

    const render = () => {
      if (context && dotsArray) {
        // clean the canvas
        context.clearRect(0, 0, innerWidth, innerHeight)
        for (const dot of dotsArray) {
          dot.move()
        }
        // re-render the dots every frame
        animationId = requestAnimationFrame(render)
      }
    }

    render()

    return () => {
      // kill rerendering when unmounted
      cancelAnimationFrame(animationId)
    }
  }, [context, dotsArray, innerWidth, innerHeight])

  useEffect(() => {
    // prepare the canvas and draw the initial dots
    const canvasCtx = canvasRef.current.getContext("2d")
    setContext(canvasCtx)
    makeCanvasGreat(canvasCtx)
    setDotsArray(initialPainting())
  }, [setContext, initialPainting, makeCanvasGreat])

  return (
    <>
      <div className={Styles.placeholder}></div>
      {!pageNotFound && (
        <AboutSection profileImage={profileImage} aboutMe={aboutMe} />
      )}

      <canvas id="canvas" className={Styles.canvas} ref={canvasRef}></canvas>
      <svg
        className={Styles.fade}
        viewBox="0 0 562 130"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="562" height="130" fill="url(#gradientBar1_linear)" />
        <defs>
          <linearGradient
            id="gradientBar1_linear"
            x1="281"
            y1="0"
            x2="281"
            y2="130"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
    </>
  )
}

export default CanvasBackgroundV2
