import React from "react"

const LostInSpace = ({ className }) => {
  return (
    <svg
      className={className}
      width="411"
      height="175"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.0822 130.265S160.48 45.1781 225.461 99.0421c64.983 53.8639 81.824 84.8369 185.039 61.4669"
        stroke="#DDD"
        strokeWidth="2.66667"
        strokeMiterlimit="10"
      />
      <path
        d="M34.2456 101.61s-14.4054-1.542-12.9574-17.9415l-5.944-5.4107s-6.23464 12.4133 3.256 23.4902c9.4974 11.082 19.6534 6.888 19.6534 6.888l-4.008-7.026z"
        fill="#636363"
      />
      <path
        opacity=".100006"
        d="M15.3447 78.2582l1.0453.9493c-1.248 4.3133-2.3867 12.7987 4.3147 20.6227 6.4266 7.4998 13.148 7.9958 16.8253 7.5358l.724 1.27s-10.156 4.194-19.6547-6.887c-9.48931-11.0775-3.2546-23.4908-3.2546-23.4908z"
        fill="#230753"
      />
      <path
        d="M69.4325 150.595c6.3893 7.607 17.7347 8.6 25.344 2.21l16.0825-13.499c7.612-6.388 8.601-17.735 2.213-25.344L79.7832 74.3194c-6.376-7.6147-17.7254-8.6027-25.332-2.216L38.3618 85.6087c-7.6053 6.3867-8.6013 17.7303-2.2106 25.3413l33.2813 39.645z"
        fill="#A0A0A0"
      />
      <path
        d="M.815309 38.529C-1.10736 61.8237 18.1726 82.4317 43.8673 84.5543c25.6853 2.12 48.088-15.0533 50.0053-38.348C95.8006 22.905 76.522 2.29699 50.8273.176994 25.13-1.94301 2.73798 15.2277.815309 38.529z"
        fill="#A0A0A0"
      />
      <path
        d="M9.16876 44.7282C7.37143 66.5002 24.1701 85.6629 46.6888 87.5162c22.5093 1.86 42.2293-14.288 44.024-36.0586 1.7946-21.772-15.0054-40.9347-37.5214-42.78938-22.5146-1.86-42.228 14.28668-44.02264 36.05998z"
        fill="#040028"
      />
      <path
        d="M84.6747 69.1814c-8.0987 15.232-25.2707 26.3187-50.728 20.6667C9.73199 84.4681-1.82801 66.4747.23466 41.4587c.630666-7.56 2.992-14.5413 6.65333-20.5466 0 0-8.85733 48.6853 29.49201 57.648 34.9213 8.168 48.2947-9.3787 48.2947-9.3787z"
        fill="#EAEAEA"
      />
      <path
        d="M17.5249 82.9l2.62-3.5213c1.5467-2.1453-.4493-6.2307-4.4573-9.1267-4.0067-2.8973-8.51336-3.5013-10.06269-1.356l-2.43067 3.296L17.5249 82.9z"
        fill="#A0A0A0"
      />
      <path
        d="M3.19422 72.1918c-1.548 2.1453.44534 6.2346 4.45467 9.1293 4.01471 2.8947 8.51871 3.504 10.06801 1.3533 1.5453-2.144-.444-6.2333-4.46-9.1253-4.00801-2.9-8.51201-3.504-10.06268-1.3573zM72.3368 21.8924s-4.7467-5.8974-15.5907-7.9214c0 0-8.5106-1.7653-8.7813 1.232 0 0-.796 3.3894 6.18 2.8827 0 0 10.824-.9507 18.192 3.8067M142.048 43.9704c-4.934 2.6014-6.788 8.7654-4.146 13.7787l2.42 4.5787c2.638 5.0106 8.778 6.9546 13.712 4.3546 4.932-2.5946 6.782-8.7706 4.14-13.7813l-2.416-4.576c-2.64-5.008-8.783-6.9587-13.71-4.3547z"
        fill="#EAEAEA"
      />
      <path
        d="M155.016 51.6358c-1.719.688-2.548 2.6453-1.852 4.3653.693 1.7174 2.649 2.5467 4.362 1.8494l9.33-3.7734c1.726-.692 2.552-2.648 1.856-4.3653-.695-1.7213-2.644-2.5467-4.366-1.8533l-9.33 3.7773z"
        fill="#EAEAEA"
      />
      <path
        d="M153.028 45.4262c-1.708 1.1094-2.27 3.2694-1.258 4.828 1.012 1.5467 3.21 1.8987 4.916.7974l9.27-6.0347c1.708-1.1067 2.266-3.2653 1.258-4.824-1.016-1.5533-3.214-1.9107-4.918-.796l-9.268 6.0293z"
        fill="#EAEAEA"
      />
      <path
        d="M146.477 43.2321c-1.22 1.628-1.012 3.8506.472 4.9613 1.479 1.1107 3.67.7 4.89-.9267l6.65-8.8333c1.234-1.628 1.019-3.8467-.457-4.964-1.488-1.1133-3.675-.7013-4.903.9293l-6.652 8.8334z"
        fill="#EAEAEA"
      />
      <path
        d="M139.667 48.5798c-.212 1.7573 1.11 3.364 2.952 3.5853 1.835.22 3.5-1.0186 3.714-2.78l1.152-9.5586c.222-1.76-1.107-3.3667-2.944-3.592-1.844-.224-3.503 1.028-3.718 2.7826l-1.156 9.5627zM153.301 56.7112c-1.769-.0746-3.269 1.3667-3.351 3.22-.082 1.8467 1.288 3.4054 3.056 3.488l9.615.42c1.777.0854 3.275-1.36 3.355-3.204.078-1.856-1.288-3.4186-3.051-3.4933l-9.624-.4307z"
        fill="#EAEAEA"
      />
      <g opacity=".100006">
        <path
          opacity=".100006"
          d="M153.417 45.1697l4.209-7.8614c.703-1.492 1.01-2.6133.487-3.76 1.397 1.1387 1.584 3.2907.377 4.8854l-5.073 6.736zM147.186 42.292l-.563-3.592c-.065-.764-.284-1.98-1.159-2.2226 3.103 1.2306 1.783 4.7933 1.722 5.8146zM153.171 65.5577c1.438-.7574 2.604-1.8227 3.483-3.0707l5.108.224c1.776.0893 3.271-1.3587 3.355-3.2013.026-.5907-.111-1.1454-.346-1.6374.771.6414 1.256 1.6467 1.206 2.764-.082 1.8427-1.579 3.288-3.356 3.2027l-5.106-.2253c-.88 1.2493-2.044 2.312-3.481 3.0693-4.641 2.448-10.351.868-13.207-3.5027 3.04 3.4934 8.128 4.5987 12.344 2.3774zM157.067 50.8024l8.024-6.9133c1.502-1.5267 2.148-2.8813 1.48-4.3853.238.196.462.4186.644.6893 1.008 1.5587.449 3.7173-1.259 4.824l-8.889 5.7853zM162.068 56.0186c-.001-.0013-2.41.9773-2.416.9773l6.344-4.044c1.638-1.0946 2.55-2.6493 1.856-4.368-.026-.0546-.061-.1053-.086-.16.401.3374.737.7654.946 1.288.696 1.7174-.129 3.6734-1.856 4.3654l-4.788 1.9413z"
          fill="#230753"
        />
      </g>
      <path
        d="M140.701 70.1957s-26.892 19.1147-36.42 11.0107l1.105-23.144s11.66 2.5853 26.263-5.4054c0 0-.838 1.2534 4.941 11.1694l4.111 6.3693z"
        fill="#EAEAEA"
      />
      <path
        opacity=".100006"
        d="M138.468 66.7321l2.232 3.464s-26.892 19.1146-36.42 11.0093l.15-3.0533c9.424 4.68 29.705-8.48 34.038-11.42z"
        fill="#230753"
      />
      <path
        d="M131.463 52.7503c.067-.0347.124-.0573.187-.0933 0 0-.059.092-.035.424-.049-.112-.103-.228-.152-.3307z"
        fill="#EAEAEA"
      />
      <path
        d="M133.236 50.4626c-1.722.9613-2.218 3.348-1.106 5.344l7.134 12.828c1.111 1.9906 3.403 2.824 5.124 1.8733 1.727-.9587 2.22-3.3507 1.114-5.3453l-7.143-12.8334c-1.107-1.9853-3.396-2.8173-5.123-1.8666z"
        fill="#636363"
      />
      <path
        opacity=".100006"
        d="M142.32 70.6227c1.724-.9586 2.217-3.3533 1.11-5.3453l-7.138-12.8307c-.628-1.1213-1.632-1.856-2.698-2.1306 1.675-.6654 3.732.1626 4.766 2.0133l7.142 12.8333c1.107 1.9934.614 4.3867-1.113 5.3454-.749.412-1.605.4706-2.427.2613.119-.0493.24-.084.358-.1467z"
        fill="#230753"
      />
      <path
        d="M93.2503 53.8038s-3.2414-1.6827-8.5547.2413c-5.2974 1.9293-24.796 9.0787-24.796 9.0787s-6.068 1.9573-9.6427 8.8453c-3.564 6.8853-10.7307 18.8733-10.7307 18.8733s-2.084 3.4694.86 7.144c2.9427 3.6676 28.8334 32.4996 28.8334 32.4996s4.6773 3.908 4.236 8.135c-.436 4.229 3.476 3.996 3.476 3.996s2.92-.593 3.168 3.136c.2506 3.725 13.8506 21.972 23.0444 11.668 9.192-10.305 37.142-39.004 37.142-39.004s5.3-5.264-.881-11.196c-6.185-5.933-7.424-1.591-7.948-8.7212 0 0-.099-3.852-2.889-4.3574-2.78-.508-1.607-2.3613-1.607-2.3613s1.749-2.172.012-4.1227c-1.739-1.948 1.688-5.8413-5.352-6.416L93.2503 53.8038z"
        fill="#EAEAEA"
      />
      <g opacity=".100006">
        <path
          opacity=".100006"
          d="M126.961 91.7817s-.064.1027-.128.2667c-.632-2.0614 1.271-5.1334-4.922-5.636L93.5412 58.971s-3.244-1.6813-8.5534.2427c-5.3026 1.9253-24.7986 9.0773-24.7986 9.0773s-6.068 1.9574-9.64 8.844c-3.5667 6.888-10.7334 18.876-10.7334 18.876s-.1413.236-.296.6414c-1.5986-3.136.0054-5.8094.0054-5.8094s7.168-11.988 10.732-18.8733c3.5746-6.888 9.6426-8.8453 9.6426-8.8453s19.4987-7.1494 24.796-9.08c5.3134-1.9227 8.5547-.24 8.5547-.24L121.621 81.243c7.039.5734 3.614 4.4667 5.351 6.4147 1.739 1.9507-.011 4.124-.011 4.124zM139.695 112.391c-6.187-5.933-7.421-1.591-7.948-8.723 0 0-.099-3.853-2.888-4.3583-2.784-.508-1.608-2.36-1.608-2.36s1.111-1.42.639-2.9894c.194.0694.414.132.677.1814 2.791.5053 2.889 4.3586 2.889 4.3586.526 7.1307 1.763 2.7877 7.95 8.7217 3.34 3.2 3.324 6.201 2.606 8.292-.466-1.003-1.198-2.051-2.317-3.123z"
          fill="#230753"
        />
      </g>
      <path
        d="M77.5737 66.5459l-1.812 9.5173c-.188 1.212.1147 2.5387.936 3.6467L93.863 104.541c1.552 2.085 3.824 2.041 6.268 1.197l22.976-9.6628c1.139-.844 1.7-2.1906 1.644-3.588l.827-6.4573M79.4247 101.69c-1.412 1.394-.976 4.119.9666 6.09 1.94 1.97 4.6654 2.436 6.0814 1.041 1.404-1.391.9693-4.119-.9707-6.085-1.9453-1.967-4.6667-2.436-6.0773-1.046zM69.484 85.0095c-1.18 1.16-.808 3.4387.804 5.0774 1.624 1.6453 3.8947 2.0333 5.068.872 1.1787-1.1587.8133-3.4347-.808-5.0734-1.6213-1.6413-3.884-2.032-5.064-.876z"
        fill="#CFCFCF"
      />
      <path
        opacity=".100006"
        d="M85.1519 103.818c-1.9454-1.968-4.6667-2.436-6.08-1.045-.1667.162-.3014.352-.42.55.0906-.629.332-1.2.7733-1.633 1.4107-1.391 4.1307-.921 6.0773 1.045 1.712 1.734 2.248 4.058 1.3854 5.54.204-1.393-.4-3.102-1.736-4.457zM74.1981 86.9678c-1.6227-1.64-3.8853-2.032-5.0627-.8747-.1266.124-.2226.264-.312.4054.056-.5747.264-1.0987.66-1.4894 1.18-1.156 3.4427-.7653 5.0654.876 1.4493 1.4667 1.8866 3.4374 1.1186 4.664.1134-1.1333-.3946-2.4946-1.4693-3.5813z"
        fill="#230753"
      />
      <path
        d="M97.0777 95.7973c1.3947 1.8693 3.4603 1.892 5.4583 1.208l21.628-8.56c1.616-1.2067 1.799-3.696.402-5.568l-18.835-25.32c-1.392-1.8707-3.324-2.2214-5.448-1.2094l-21.6306 8.5627c-1.6267 1.204-1.8054 3.6973-.416 5.5693l18.8413 25.3174zM82.288 102.843c-.888.927-.812 2.428.16 3.353.968.926 2.46.927 3.3413.004l7.0733-7.3852c.8787-.9226.8174-2.4253-.1573-3.352-.968-.9253-2.4707-.9293-3.3533-.0013l-7.064 7.3815z"
        fill="#A0A0A0"
      />
      <path
        opacity=".100006"
        d="M82.288 102.843l7.064-7.3815c.8826-.928 2.3853-.924 3.3533.0013.244.2347.432.504.5587.792-.9587-.716-2.2907-.66-3.1054.192l-7.0626 7.3872c-.66.688-.788 1.702-.4027 2.553-.0827-.061-.168-.117-.2453-.191-.972-.925-1.048-2.426-.16-3.353z"
        fill="#230753"
      />
      <path
        d="M90.685 76.9848c.7413 1.0186 2.2853 1.164 3.444.312l13.232-5.4307c1.163-.844 1.496-2.3547.751-3.38l-5.123-7.0213c-.745-1.02-1.775-1.0707-3.4493-.3147l-13.228 5.4307c-1.16.848-1.4947 2.3573-.7494 3.38l5.1227 7.024z"
        fill="#040028"
      />
      <path
        d="M122.535 143.704s1.586-2.379-3.7-7.916c-5.288-5.536-18.412-26.267-43.0257-.955l33.9107 28.288s3.833 1.789 6.009-.991c2.179-2.786 6.806-18.426 6.806-18.426z"
        fill="#EAEAEA"
      />
      <path
        d="M109.379 138.167l-8.115-5.927c-2.3882-1.742-2.7775-5.274-.869-7.889.422-.575.925-1.036 1.462-1.419 7.838 1.69 13.054 8.439 16.162 11.959-.203.583-.471 1.159-.859 1.696-1.911 2.613-5.393 3.323-7.781 1.58z"
        fill="#636363"
      />
      <path
        opacity=".100006"
        d="M120.632 147.952s1.585-2.38-3.698-7.916c-5.052-5.282-18.6036-25.522-41.125-5.204 24.613-25.31 37.737-4.58 43.025.955 5.287 5.537 3.7 7.916 3.7 7.916s.388.36-1.902 4.249c0 0-.772.845 0 0z"
        fill="#230753"
      />
      <path
        d="M149.517 174.206s-5.124 3.695-16.908-5.137c0 0-8.67-6.491-16.556-5.708 0 0-3.478 1.085-3.424-2.946.051-4.022 8.779-17.904 8.779-17.904s.593-1.618 4.047-.174c3.452 1.438 8.934 3.356 14.762 9.68 0 0 1.338-2.086 5.783-11.312 4.449-9.234 10.023-1.926 10.023-1.926l-6.506 35.427z"
        fill="#00B8E2"
      />
      <path
        d="M149.517 174.206s-5.124 3.695-16.908-5.137c0 0-8.67-6.491-16.556-5.708 0 0-3.478 1.085-3.424-2.946.051-4.022 8.779-17.904 8.779-17.904s.593-1.618 4.047-.174c3.452 1.438 8.934 3.356 14.762 9.68 0 0 1.338-2.086 5.783-11.312 4.449-9.234 10.023-1.926 10.023-1.926l-6.506 35.427z"
        fill="#636363"
      />
      <g opacity=".100006">
        <path
          opacity=".100006"
          d="M153.814 141.767s-3.741-4.893-7.537-1.598c4.398-8.388 9.746-1.39 9.746-1.39l-6.506 35.427s-.582.405-1.778.623l6.075-33.062zM138.007 155.003c-5.831-6.319-11.315-8.24-14.766-9.681-1.68-.702-2.678-.678-3.261-.466.852-1.426 1.428-2.345 1.428-2.345s.593-1.619 4.047-.175c3.374 1.408 8.702 3.287 14.393 9.291-1.351 2.608-1.841 3.376-1.841 3.376z"
          fill="#230753"
        />
      </g>
      <path
        d="M145.365 165.286c-.713 4.514.79 8.506 3.367 8.918 2.569.407 5.239-2.918 5.956-7.437l3.168-19.84c.711-4.513-.796-8.504-3.365-8.916-2.574-.411-5.238 2.917-5.959 7.433l-3.167 19.842z"
        fill="#A0A0A0"
      />
      <path
        opacity=".100006"
        d="M156.333 140.705c-2.569-.415-5.236 2.913-5.956 7.429l-3.168 19.843c-.336 2.141-.165 4.15.375 5.729-1.846-1.252-2.814-4.636-2.219-8.42l3.168-19.841c.72-4.516 3.384-7.844 5.959-7.435 1.352.217 2.396 1.44 2.993 3.191-.359-.242-.733-.432-1.152-.496z"
        fill="#230753"
      />
      <path
        d="M60.5119 151.164c.42-5.666-3.9147-10.616-9.672-11.036l-5.2694-.389c-5.7613-.419-10.7706 3.832-11.188 9.505-.416 5.671 3.9174 10.615 9.6814 11.036l5.2613.383c5.764.431 10.7693-3.827 11.1867-9.499z"
        fill="#EAEAEA"
      />
      <path
        d="M46.8732 158.231c.3253-1.861-.9214-3.633-2.7827-3.961-1.8573-.33-3.6307.913-3.96 2.776l-1.7787 10.106c-.3346 1.866.912 3.639 2.772 3.967 1.864.328 3.6347-.915 3.968-2.773l1.7814-10.115z"
        fill="#EAEAEA"
      />
      <path
        d="M53.3101 159.866c-.0307-2.072-1.6013-3.725-3.492-3.688-1.8853.035-3.388 1.744-3.348 3.817l.208 11.276c.032 2.079 1.5947 3.73 3.4853 3.694 1.892-.036 3.384-1.74 3.3547-3.816l-.208-11.283z"
        fill="#EAEAEA"
      />
      <path
        d="M58.7489 155.399c-.7493-1.94-2.7827-2.959-4.5453-2.279-1.7667.675-2.5867 2.795-1.8427 4.728l4.0413 10.532c.744 1.941 2.776 2.955 4.5427 2.283 1.768-.68 2.5907-2.798 1.8427-4.735l-4.0387-10.529z"
        fill="#EAEAEA"
      />
      <path
        d="M57.8147 146.618c-1.4107-1.136-3.512-.862-4.7014.606-1.184 1.471-1.0026 3.58.3947 4.719l7.6413 6.163c1.4067 1.144 3.5054.865 4.6934-.603 1.1893-1.475 1.0106-3.581-.3907-4.717l-7.6373-6.168zM43.3966 154.006c1.024-1.487.5974-3.564-.964-4.636-1.5493-1.068-3.6413-.735-4.672.756l-5.564 8.088c-1.0226 1.488-.5893 3.558.9654 4.634 1.56 1.072 3.6413.734 4.672-.76l5.5626-8.082z"
        fill="#EAEAEA"
      />
      <g opacity=".100006">
        <path
          opacity=".100006"
          d="M63.5497 156.74c1.188-1.476 1.008-3.583-.3907-4.72l-5.0746-4.096c-.5694-3.324-2.784-6.137-5.824-7.571 4.204.89 7.4106 4.226 8.1213 8.334l5.0693 4.098c1.4027 1.136 1.58 3.243.392 4.718-1.188 1.468-3.2866 1.746-4.6933.602l-.0987-.078c.9294-.055 1.848-.487 2.4987-1.287zM58.65 169.896c1.7666-.679 2.1693-1.761 1.8453-4.732l-1.3173-8.649c.2533.205-.2867-.115 0 0l3.6106 9.413c.748 1.937-.076 4.055-1.844 4.735-1.1253.428-2.356.166-3.296-.58.336-.012.6747-.059 1.0014-.187zM43.1226 168.269l3.36-7.644-1.4533 7.997c-.7213 2.243-2.6333 2.723-3.9053 2.497-.592-.102-1.1094-.366-1.5454-.718 1.5667-.072 2.708-.771 3.544-2.132zM36.7359 161.621l2.992-2.282-2.076 2.99c-1.3747 1.59-3.408 1.264-4.4907.519-.068-.047-.1227-.107-.1867-.155 1.0054-.038 1.8934.299 3.7614-1.072zM51.2229 170.383l2.096-10.039.2 10.804c.0293 2.076-1.4627 3.78-3.356 3.818-.804.013-1.54-.29-2.132-.786 1.8133-.129 2.5467-.822 3.192-3.797z"
          fill="#230753"
        />
      </g>
      <path
        d="M55.0262 135.938l-16.504 1.128s-4.344-5.51-3.3453-27.35l1.0853-11.4102c.6347-6.7373 6.6254-11.68 13.36-11.0413 6.74.64 11.6814 6.6213 11.0467 13.3625 0 0 .4707 14.803-5.6427 35.311z"
        fill="#EAEAEA"
      />
      <path
        opacity=".100006"
        d="M55.1897 101.664c.636-6.7396-4.308-12.7236-11.0466-13.3636-.2187-.0213-.4387-.02-.6574-.028 1.8654-.8333 3.9614-1.216 6.136-1.008 6.74.64 11.6814 6.6214 11.048 13.3626 0 0 .4707 14.803-5.644 35.311l-5.28.364c5.9014-20.158 5.444-34.638 5.444-34.638z"
        fill="#230753"
      />
      <path
        d="M35.7137 104.046c1.004-.739 2.2614-1.197 3.6534-1.2 3.2333-.008 5.864 2.381 5.8746 5.339l.02 10.044c.0067 2.958-2.6106 5.362-5.8466 5.364-1.6534.009-3.1414-.618-4.208-1.618-.1987-3.369-.256-7.386-.0294-12.258l.536-5.671zM35.0346 140.912c.1654 1.962 2.1374 3.401 4.4027 3.217l14.6347-1.208c2.2693-.184 3.9786-1.929 3.8186-3.889-.1613-1.968-2.132-3.406-4.404-3.223l-14.6373 1.207c-2.2693.185-3.976 1.928-3.8147 3.896z"
        fill="#636363"
      />
      <path
        opacity=".100006"
        d="M56.6362 140.608c-.1613-1.962-2.132-3.405-4.4013-3.22l-14.6373 1.206c-.8974.076-1.696.396-2.3387.87.5307-1.309 1.9027-2.31 3.5907-2.448l14.6373-1.208c2.272-.181 4.2427 1.256 4.404 3.223.0973 1.187-.4973 2.285-1.48 3.015.184-.447.2653-.935.2253-1.438z"
        fill="#230753"
      />
      <path
        d="M171.104 109.978s-21.541-22.3518-45.95-8.058c-8.8 5.15-12.103 8.421-12.103 8.421l11.173 20.704s21.963-5.513 37.092-.187c0 0 3.455.931 5.018-1.252l4.77-19.628z"
        fill="#EAEAEA"
      />
      <path
        d="M159.477 107.664l-9.466-3.361c-2.638-.94-3.99-3.92-3.209-6.8186 7.913 1.124 14.54 4.8676 18.868 7.9676-1.436 2.038-3.92 3.022-6.193 2.212z"
        fill="#636363"
      />
      <path
        opacity=".100006"
        d="M126.456 104.271c-6.626 3.052-11.614 4.635-13.405 6.071 0 0 3.301-3.271 12.103-8.423 24.409-14.2919 45.95 8.059 45.95 8.059l-.885 3.641c-3.909-3.657-22.331-19.2332-43.763-9.348z"
        fill="#230753"
      />
      <path
        d="M199.983 140.01s-5.126 3.694-16.908-5.134c0 0-7.038-3.865-14.931-3.081 0 0-3.399.179-3.339-3.848.052-4.029 5.068-18.259 5.068-18.259s.599-1.62 4.056-.173c3.444 1.444 10.92 1.985 16.748 8.303 0 0 1.343-2.086 5.788-11.311 4.451-9.2254 10.026-1.921 10.026-1.921l-6.508 35.424z"
        fill="#636363"
      />
      <path
        opacity=".100006"
        d="M205.403 107.193s-5.578-7.3025-10.024 1.924c-4.448 9.224-4.726 10.599-4.726 10.599-5.825-6.323-14.362-6.148-17.813-7.592-2.249-.94-3.276-.583-3.724-.223.456-1.351.757-2.213.757-2.213s.599-1.62 4.056-.174c3.446 1.444 10.92 1.986 16.75 8.304 0 0 1.341-2.086 5.786-11.312 4.451-9.2249 10.026-1.921 10.026-1.921l-6.508 35.424s-.227.156-.668.323l6.088-33.139z"
        fill="#230753"
      />
      <path
        d="M195.825 131.09c-.712 4.516.793 8.504 3.357 8.919 2.583.411 5.254-2.919 5.967-7.44l3.161-19.833c.722-4.522-.788-8.511-3.354-8.918-2.576-.412-5.24 2.915-5.963 7.43l-3.168 19.842z"
        fill="#A0A0A0"
      />
      <path
        opacity=".100006"
        d="M206.561 106.739c-2.575-.414-5.239 2.916-5.962 7.429l-3.169 19.84c-.299 1.915-.184 3.712.22 5.203-1.58-1.44-2.377-4.61-1.825-8.12l3.169-19.843c.721-4.515 3.385-7.841 5.961-7.429 1.479.233 2.591 1.681 3.142 3.716-.454-.414-.962-.706-1.536-.796z"
        fill="#230753"
      />
      <path
        d="M71.6024 85.8012c-.732.7773-.676 2.0266.1306 2.7986.8107.768 2.0574.7667 2.7934.0014l5.8853-6.1614c.7427-.768.6813-2.0146-.128-2.7906-.808-.7707-2.0547-.772-2.792-.004l-5.8893 6.156z"
        fill="#A0A0A0"
      />
      <path
        opacity=".100006"
        d="M80.6421 80.8662c-.808-.7747-2.0547-.776-2.792-.0067l-5.8893 6.1574c-.3227.3413-.464.7773-.4854 1.216-.5146-.7587-.504-1.7627.1267-2.432l5.8893-6.156c.7374-.768 1.984-.7667 2.792.004.4547.436.648 1.0173.62 1.5826-.0853-.1266-.148-.2586-.2613-.3653z"
        fill="#230753"
      />
      <path
        d="M93.2984 83.3727c-.1853 1.7374 1.0707 3.296 2.8093 3.4814 1.7347.1826 3.2947-1.0707 3.4787-2.812l.036-1.2227-6.296-.2387-.028.792z"
        fill="#636363"
      />
      <path
        d="M93.3264 82.5811c-.1627 1.528 1.1093 2.9213 2.8493 3.1093 1.7347.1814 3.2774-.908 3.4374-2.4386.1666-1.532-1.108-2.92-2.8467-3.1054-1.7387-.188-3.2747.904-3.44 2.4347zM108.33 77.3063c-.182 1.7347 1.073 3.292 2.812 3.4814 1.736.18 3.294-1.0747 3.478-2.812l.035-1.2214-6.296-.2413-.029.7933z"
        fill="#636363"
      />
      <path
        d="M108.359 76.5134c-.161 1.528 1.111 2.9187 2.851 3.108 1.733.184 3.276-.904 3.44-2.4387.164-1.5293-1.114-2.9186-2.854-3.1026-1.734-.188-3.27.9026-3.437 2.4333zM101.106 80.2165c-.184 1.736 1.071 3.2933 2.811 3.4826 1.733.18 3.296-1.0706 3.477-2.812l.039-1.2226-6.297-.24-.03.792z"
        fill="#636363"
      />
      <path
        d="M101.136 79.4249c-.163 1.528 1.112 2.92 2.852 3.108 1.733.1826 3.272-.9054 3.437-2.436.164-1.532-1.112-2.9214-2.849-3.1054-1.738-.188-3.274.904-3.44 2.4334z"
        fill="#636363"
      />
      <path
        d="M44.2717 17.0134c0 1.304-1.056 2.3614-2.36 2.3614-1.304 0-2.36-1.0574-2.36-2.3614 0-1.304 1.056-2.36 2.36-2.36 1.304 0 2.36 1.056 2.36 2.36z"
        fill="#EAEAEA"
      />
    </svg>
  )
}

export default LostInSpace
